<template>
  <div>
    <!-- <v-row justify="center" class="mt-5">
      <v-col
        class="text-center"
        v-if="latestReadings.length"
        v-for="(value, name, i) in latestVals"
        :id="name"
      >
        <v-chip label large class="val">
          <div>{{ `${name.toUpperCase()}` }}
            <br>
          {{ `${value}℉` }}</div>
        </v-chip>
      </v-col>
    </v-row> -->
    <Chart v-if="chartdata" ref="chart" :chart-data="chartdata" :options="otherOptions" />
    <!-- <v-btn class="ml-2" @click="deleteAll">Delete All</v-btn> -->
    <!-- <v-card v-if="treatment" class="mt-5">
      <v-card-title>
        <span>Readings</span>
        <v-btn class="ml-2" @click="deleteAll">Delete All</v-btn>
        <div class="flex-grow-1"></div>
        <v-text-field xs12 color="#012b45" v-model="search" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="readings.length"
        :headers="headers"
        :items="readings"
        :items-per-page="20"
        item-key="id"
        class="elevation-1"
        :search="search"
        sort-by="createdAt"
        :sort-desc="true"
        :mobile-breakpoint="0"
      >
        <template v-slot:body="{items}">
          <tbody>
            <template v-for="item in items">
              <tr :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.sensor.id }}</td>
                <td>{{ item.sensor.name }}</td>
                <td>{{ lastUpdated(item.createdAt) }}</td>
                <td>{{ item.min }}℉</td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { path } from "ramda";
import Chart from "@/components/Chart";
export default {
  name: "treatment",
  components: {
    Chart,
  },
  data() {
    return {
      search: "",
      otherOptions: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: "minute",
                tooltipFormat: "MMM D, h:mm a",
                stepSize: 5,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                // suggestedMin: 30,
                // suggestedMax: 40,
                // min: 30,
                // max: 50,
                // stepSize: 2
              },
            },
          ],
        },
        legend: {
          display: true,
        },
      },
      sampleTemps: [
        {
          id: Math.floor(100000 + Math.random() * 900000),
          createdAt: "",
          min: 65,
          sensor: {
            name: "wireless-1",
          },
        },
        {
          id: Math.floor(100000 + Math.random() * 900000),
          createdAt: "",
          min: 66,
          sensor: {
            name: "wireless-2",
          },
        },
        {
          id: Math.floor(100000 + Math.random() * 900000),
          createdAt: "",
          min: 67,
          sensor: {
            name: "wireless-3",
          },
        },
        {
          id: Math.floor(100000 + Math.random() * 900000),
          createdAt: "",
          min: 68,
          sensor: {
            name: "wireless-4",
          },
        },
        {
          id: Math.floor(100000 + Math.random() * 900000),
          createdAt: "",
          min: 70,
          sensor: {
            name: "wireless-5",
          },
        },
      ],
      currentData: [],
      chartdata: null,
      chartLow1: 67,
      chartLow2: 64,
      chartHigh1: 75,
      chartHigh2: 78,
      loaded: false,
      currentItem: "",
      pagination: {
        sortBy: "createdAt",
        sortDesc: true,
      },
      headers: [
        // {
        //   text: "Id",
        //   value: "id",
        // },
        // {
        //   text: "Sensor ID",
        //   value: "sensor.id",
        // },
         {
          text: "Sensor Name",
          value: "sensor.name",
        },
        {
          text: "Created",
          value: "createdAt",
        },
        {
          text: "Temp",
          value: "min",
        },
      ],
      values: ["min"],
      colors: ["#23D0DC","#DC2F23", "#74DC23", "#8B23DC", "#0C25F3"],
      now: this.$moment().toISOString(),
      base: 1,
      latestVals: {}
    };
  },
  async created() {
    await this.$store.dispatch(
      "fuelcell/singleTreatment",
      this.$route.params.id
    );
    // await this.$store.commit("fuelcell/setTreatment", {
    //   id: "ac4e064f-754a-4195-962e-538259120342",
    //   active: true,
    //   readings: {
    //     items: this.sampleTemps.map((item) => {
    //       item.createdAt = this.now;
    //       return item;
    //     }),
    //   },
    //   fuelCell: {
    //     name: "1",
    //     id: "ac4e064f-754a-4195-962e-538259120342",
    //     sensors: {
    //       items: [
    //         {
    //           id: "0e4b09c5-ad79-4d7f-8965-c73d6d757e76",
    //           name: "wireless-1",
    //           currentTemp: 72,
    //           online: false,
    //         },
    //         {
    //           id: "29cbdd22-15a1-4f2a-b79a-1ae5e34f38cd",
    //           name: "wireless-5",
    //           currentTemp: 70,
    //           online: false,
    //         },
    //         {
    //           id: "9d5a9e1a-55ec-48bf-a962-c6cae2dc5cda",
    //           name: "wireless-4",
    //           currentTemp: 70,
    //           online: false,
    //         },
    //         {
    //           id: "f6935960-325e-4639-8f9d-5cf39c70bf6c",
    //           name: "wireless-3",
    //           currentTemp: 73,
    //           online: false,
    //         },
    //         {
    //           id: "14828888-649f-4e82-b2a9-63487c250dbe",
    //           name: "wireless-2",
    //           currentTemp: 71,
    //           online: false,
    //         },
    //       ],
    //     },
    //   },
    // });
    // this.testSensors();
    // this.setChartVals();
    // this.setData();
  },
  watch: {
    readings(data) {
      this.setData();
    },
    sensorReadings(data) {
      let newData = Object.values(data)
      // console.log(newData)
      let latest = {}
      newData.forEach((item,i) => {
        latest[item.name] = item.readings[item.readings.length - 1].min
      })
      // let final = Object.entries(latest).sort().reduce( (o,[k,v]) => (o[k]=v,o), {} )
      this.latestVals = latest
    }
  },
  computed: {
    ...mapState("fuelcell", ["treatment"]),
    ...mapGetters("fuelcell", ["readings"]),
    // latestReadings() {
    //   if (!path(["fuelCell", "sensors", "items"], this.treatment)) {
    //     return [];
    //   } else
    //     return this.treatment.fuelCell.sensors.items
    //       .map((item) => {
    //         return {
    //           id: item.id,
    //           name: item.name,
    //           currentTemp: item.currentTemp,
    //         };
    //       })
    //       .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
    // },
    // sensorNumbers() {
    //   return this.readings.reduce((acc, num) => {
    //     let sensor = num.sensor.name;
    //     if (!acc.hasOwnProperty(sensor)) {
    //       acc[sensor] = 1;
    //     } else acc[sensor] = acc[sensor] + 1;
    //     return acc;
    //   }, {});
    // },
    sensorReadings() {
      return this.readings
        .reduce((acc, num) => {
          let sensor = num.sensor.name;
          let found = acc.findIndex((item) => {
            return item.name === sensor;
          });
          if (found !== -1) {
            acc[found].readings.push({
              createdAt: num.createdAt,
              min: num.min,
            });
          } else {
            acc.push({
              name: sensor,
              readings: [
                {
                  createdAt: num.createdAt,
                  min: num.min,
                },
              ],
            });
          }
          return acc;
        }, [])
        .map((item) => {
          item.readings.sort((a, b) => {
            return a.createdAt > b.createdAt
              ? 1
              : a.createdAt < b.createdAt
              ? -1
              : 0;
          });
          return item;
        });
    },
    allDatasets() {
      return this.sensorReadings.map((item, i) => {
        return {
          label: item.name,
          borderColor: this.colors[i],
          backgroundColor: this.colors[i],
          radius: 0,
          borderWidth: 2,
          fill: false,
          data: item.readings.map((item) => {
            return {
              x: new Date(item.createdAt),
              y: item.min,
            };
          }),
        };
      });
    },
  },
  methods: {
    async testSensors() {
      let samples = JSON.parse(JSON.stringify(this.sampleTemps));
      samples.map((item, i) => {
        item.id = Math.floor(100000 + Math.random() * 900000);
        item.createdAt = this.$moment(this.now)
          .add(this.base, "m")
          .toISOString();
        item.min = Math.floor(Math.random() * (70 - 65 + 1) + 65);
        this.$store.commit("fuelcell/addSingleReading", item);
      });
      this.base++;
      await this.wait(1000);
      if (this.base < 61) {
        this.testSensors();
      }
    },
    async wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    chartGradient(context) {
      let gradient = null;
      let width = null;
      let height = null;
      let chartArea = context.chart.chartArea;
      if (!chartArea) {
        return null;
      }
      let chartWidth = chartArea.right - chartArea.left;
      let chartHeight = chartArea.bottom - chartArea.top;
      if (gradient === null || width !== chartWidth || height !== chartHeight) {
        width = chartWidth;
        height = chartHeight;
        let ctx = context.chart.ctx;
        gradient = ctx.createLinearGradient(
          0,
          chartArea.bottom,
          0,
          chartArea.top
        );

        let colors = this.colorStop();
        colors.forEach((item) => {
          gradient.addColorStop(item.value, item.color);
        });
      }
      return gradient;
    },
    colorStop(val) {
      let vals = [];

      // green
      if (this.high < this.chartHigh1) {
        vals.push({
          color: "green",
          value: 0,
        });
      }
      // yellow
      else if (this.low >= this.chartHigh1 && this.high <= this.chartHigh2) {
        vals.push({
          color: "#d8d300",
          value: 0,
        });
      }
      // red
      else if (this.low > this.chartHigh2) {
        vals.push({
          color: "red",
          value: 0,
        });
      }
      // green, yellow and red
      else if (this.low < this.chartHigh1 && this.high >= this.chartHigh2) {
        vals.push(
          {
            color: "green",
            value: 0,
          },
          {
            color: "#d8d300",
            value:
              Math.abs(this.low - this.chartHigh1) / (this.high - this.low),
          },
          {
            color: "red",
            value:
              Math.abs(this.low - this.chartHigh2) / (this.high - this.low),
          }
        );
      }
      // green and yellow
      else if (
        this.low < this.chartHigh1 &&
        this.high >= this.chartHigh1 &&
        this.high < this.chartHigh2
      ) {
        vals.push(
          {
            color: "green",
            value: 0,
          },
          {
            color: "#d8d300",
            value:
              Math.abs(this.low - this.chartHigh1) / (this.high - this.low),
          }
        );
      }
      // yellow and red
      else if (this.low >= this.chartHigh1 && this.high >= this.chartHigh2) {
        vals.push(
          {
            color: "#d8d300",
            value: 0,
          },
          {
            color: "red",
            value:
              Math.abs(this.low - this.chartHigh2) / (this.high - this.low),
          }
        );
      }
      return vals;
    },
    setData(data) {
      this.chartdata = {
        datasets: this.allDatasets,
      };
    },
    // setChartVals() {
    //   this.chartLow1 = this.sensorDetails.low
    //     ? this.sensorDetails.low + 3
    //     : -97;
    //   this.chartLow2 = this.sensorDetails.low ? this.sensorDetails.low : -100;
    //   this.chartHigh1 = this.sensorDetails.high
    //     ? this.sensorDetails.high - 3
    //     : 97;
    //   this.chartHigh2 = this.sensorDetails.high ? this.sensorDetails.high : 100;
    // },
    lastUpdated(date) {
      let fullDate = new Date(date).toLocaleDateString();
      let todayDate = new Date().toLocaleDateString();
      if (fullDate === todayDate) {
        fullDate = "Today";
      }
      return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
    },
    async deleteAll() {
      let results = await Promise.all(
        this.readings.map((item) => {
          return this.$store.dispatch("fuelcell/deleteSingleReading", {
            id: item.id,
            createdAt: item.createdAt,
          });
        })
      );
      if (results.every(Boolean)) {
        this.$store.commit("fuelcell/emptyReadings");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
}
table {
  .v-text-field {
    border-color: #ffffff1f;
    padding-top: 0px;
    margin-top: 0px;
  }
}
.col{
  .v-chip{
    width: 50%;
    justify-content: center;
    border: 1px solid black;
  }
}
</style>